<template>
  <section :aria-label="section.headline">
    <div
      :class="{ dark: section.dark_mode }"
      v-bind:style="{
        'background-image': 'url(' + section.backgroundImage + ') ',
      }"
      class="
        component
        position-relative
        c-custom
        padding-y-24
        bp-768:padding-y-48
      "
      :id="'custom-' + section.id"
    >
      <div
        v-if="section.headline"
        class="row padding-x-12 position-relative z-index-3"
      >
        <common-header
          :headline="section.headline"
          :sponsor="section.sponsor"
        />
      </div>
      <div
        class="
          c-custom__content
          row
          padding-y-24 padding-x-12
          position-relative
          z-index-3
        "
      >
        <div
          class="flex-item-1 c-custom__content-inside"
          v-html="section.text"
        ></div>
      </div>
      <div
        class="position-relative z-index-3"
        v-if="custom_styles"
        v-html="custom_styles"
      ></div>
    </div>
  </section>
</template>

<script>
import SectionBase from "./SectionBase";
import CommonHeader from "@/components/common/CommonHeader";
export default {
  extends: SectionBase,
  name: "SectionCustom",
  components: { CommonHeader },
  data: () => ({}),
  computed: {
    custom_styles() {
      if (this.section.custom_styles) {
        return this.prepend_styles(
          this.section.custom_styles,
          "#custom-" + this.section.id
        );
      } else {
        return "";
      }
    },
  },
  methods: {
    get_scripts() {
      if (this.section.text) {
        var regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gm;
        var result = this.section.text.matchAll(regex);
        for (const item of result) {
          if (item[0]) {
            var str = item[0];
            var regex_str = /<script>.*?<\/script>/;
            var regex_str_with_src = /<script.*?src="(.*?)"/;
            var url = regex_str_with_src.exec(str);
            var src;

            // The script is inline
            if (url === null) {
              url = regex_str.exec(str);
              url = url[0].replace("<script>", "");
              // eslint-disable-next-line no-useless-escape
              url = url.replace("</\script>", "");
              src = url;
              let script = document.createElement("script");
              script.setAttribute("type", "text/javascript");
              try {
                script.appendChild(document.createTextNode(src));
                document.body.appendChild(script);
              } catch (e) {
                script.text = src;
                document.body.appendChild(script);
              }
            } else {
              str = item[0];
              regex_str = /<script.*?src="(.*?)"/;
              url = regex_str.exec(str);
              src = url[1];
              let script = document.createElement("script");
              script.setAttribute("src", src);
              script.setAttribute("type", "text/javascript");
              document.body.appendChild(script);
            }
          }
        }
      }
    },
    prepend_styles(styles, prepend) {
      if (!styles) {
        return "";
      }
      if (!prepend) {
        return styles;
      }
      var prepend_parts = prepend.split(",");
      var all_styles = "";
      prepend_parts.forEach((value, index) => {
        var style = prepend_parts[index] + " " + styles;
        all_styles += style.replace(
          /([|}][\s$]*)([.#]?-?[_a-zA-Z]+[_a-zA-Z0-9-]*)/g,
          "$1 " + prepend_parts[index] + " $2"
        );
      });

      return "<style>" + all_styles + "</style>";
    },
  },
  mounted() {
    this.get_scripts();
  },
};
</script>

<style lang="scss">
.c-custom {
  &__content-inside {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>

<style scoped lang="scss">
.c-custom {
  color: $main-font-color;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $main-color;
    opacity: 0.7;
    z-index: 2;
  }
}
</style>
